"use client";

import { captureMessage, setExtras } from "@sentry/nextjs";
import { type IntlShape } from "react-intl";

import { Signs } from "../backend";

export const getResponseErrorMessage = (
  responseError: 0 | Signs,
  intl: IntlShape,
): string | undefined => {
  switch (responseError) {
    case 0: {
      return intl.formatMessage({
        defaultMessage: "Неизвестная ошибка",
        id: "leEcct",
      });
    }

    case Signs.NUMBER_1: {
      return intl.formatMessage({
        defaultMessage: "Нет прав для выполнения данной операции",
        id: "PLVPxI",
      });
    }

    case Signs.NUMBER_2: {
      return intl.formatMessage({
        defaultMessage: "Запись не найдена",
        id: "z05wDq",
      });
    }

    case Signs.NUMBER_3: {
      return intl.formatMessage({
        defaultMessage: "Запись уже существует",
        id: "skAeRl",
      });
    }

    case Signs.NUMBER_4: {
      return intl.formatMessage({
        defaultMessage: "Неверный токен",
        id: "4atVEw",
      });
    }

    case Signs.NUMBER_5: {
      return intl.formatMessage({
        defaultMessage: "Пользователь забанен",
        id: "bFsDSp",
      });
    }

    case Signs.NUMBER_6: {
      return intl.formatMessage({
        defaultMessage: "Неверный пароль",
        id: "DlRIaG",
      });
    }

    case Signs.NUMBER_7: {
      return intl.formatMessage({
        defaultMessage: "Нет связи родителя с ребёнком",
        id: "HdFeKx",
      });
    }

    case Signs.NUMBER_8: {
      return intl.formatMessage({
        defaultMessage: "Нельзя создать пользователя с такой ролью",
        id: "C/I27u",
      });
    }

    case Signs.NUMBER_9: {
      return intl.formatMessage({
        defaultMessage: "Неверный логин или пароль",
        id: "8B5OJY",
      });
    }

    case Signs.NUMBER_10: {
      return intl.formatMessage({
        defaultMessage: "Недостаточно средств на балансе",
        id: "hVXhA3",
      });
    }

    default: {
      setExtras({ responseError });
      captureMessage("Localized message is not provided");

      return intl.formatMessage(
        {
          defaultMessage: "Ошибка {status}",
          id: "bT5DtX",
        },
        {
          status: responseError,
        },
      );
    }
  }
};

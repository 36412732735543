
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"033e69ebf72bc9980ebce8fe8168acedd1467bf2":"signInAction","2bb906d100e3a7ee46dda1ece8049be8643fbce5":"$$ACTION_0"} */ export var signInAction = createServerReference("033e69ebf72bc9980ebce8fe8168acedd1467bf2");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

